import React from "react";
import { useInView } from "react-intersection-observer";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Clients from "../components/Clients";

import registerToVote from "../img/register.png";
import homeCircle1 from "../img/home_circle1.png";
import homeCircle2 from "../img/home_circle2.png";
import homeCircle3 from "../img/home_circle3.png";
import useWindowDimensions from "../components/windowDimensions";
import heroImage from "../img/home_hero.png";
import heroVideo from "../video/impactual_home_video.mp4";

const Value = ({ title, text, image, imageAlt, reverse }) => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true
  });

  return (
    <div ref={ref} className={reverse ? "value value--reverse" : "value"}>
      <div className={`${inView ? "visible" : "hidden"} value__image`}>
        <img src={image} alt={imageAlt} />
      </div>
      <div className={`${inView ? "visible" : "hidden"} value__text`}>
        <h2>{title}</h2>
        <p className="body-1">{text}</p>
      </div>
    </div>
  );
};

const Quote = ({ quoteText, quoteCredit, quoteCreditOrg }) => (
  <div className="quote">
    <div className="container">
      <div className="columns">
        <div className="column is-8 is-offset-4">
          <p className="quote__text h3">{quoteText}</p>
          <p className="quote__credit h4">—{quoteCredit}{quoteCreditOrg ? ',' : ''} {quoteCreditOrg}</p>

        </div>
      </div>
    </div>
  </div>
);

export const IndexPageTemplate = ({ title, description }) => {
  const { width } = useWindowDimensions();
  const mobileBreakpoint = 769;
  const showVideo = width >= mobileBreakpoint;

  return (
  <React.Fragment>
    <section className="hero">
      <video className="hero-background-video" autoPlay loop muted poster={heroImage}>
        {showVideo && (<source src={heroVideo} type="video/mp4" />)}
       </video>

      <div className="container">
        <div className="columns is-vcentered title ">

          <div className="heroTitle column is-10 is-offset-2">
            <h4 className="">{description}</h4>
            <h1 className="">
              Make an <br /> actual impact.
            </h1>
          </div>
        </div>
      </div>
          <a
            href="https://turbovote.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="register"
            title="Register to Vote"
          >
            <img src={registerToVote} alt="Register to vote" />
          </a>


    </section>
    <section className="secondary-hero">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <p className="h2">
              We believe that combining defined goals with cultural strategies and proper resources
              is the key to moving needles.
            </p>
            <Link className="primary-link" to="/about" title="Learn more">
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </section>
    <div className="quote-wrapper">
      <Quote
        quoteText="I love Impactual. It's just people making shit happen. Truly the highest action-to-bullshit ratio in the business."
        quoteCredit="Sam Novey"
        quoteCreditOrg="Center for Democracy and Civic Engagement at University of Maryland"
      />
      <Quote
        quoteText="I'm deeply grateful for your persistence, foresight, leadership, and determination. You saw openings that no one else saw and made things happen that no one else could do. You created huge new programs that needed to exist and you strengthened the field while doing it."
        quoteCredit="Impactual Client"
      />
    </div>
    <Clients />
    <section className="contact-block">
      <Link className="case-studies-link" to="/work">
        View our work
      </Link>
      <div className="container">
        <h2>
          Make An <br /> Actual Impact
        </h2>
        <Link className="primary-link h1" to="/contact">
          Contact us
        </Link>
      </div>
    </section>
  </React.Fragment>
)};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout pageId="home">
      <IndexPageTemplate title={frontmatter.title} description={frontmatter.description} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
      }
    }
  }
`;
