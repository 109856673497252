import React from "react";
import { StaticQuery, graphql } from "gatsby";

const Clients = ({ data }) => {
  const clients = data.allDataYaml.nodes.filter(n => n.clients !== null)[0].clients;
  return (
    <section className="clients">
      <div className="container">
        <h2>Our clients</h2>
        <div className="columns">
          <p className="column is-6 description">Impactual has supported some of the country's largest social impact and civic engagement initiatives for individuals, nonprofits, and brands including:</p>
        </div>
        <div className="columns">

          <ul className="column is-9 is-offset-3">
            {clients.map((c, i) => (
              <li key={i} className="client">
                <img src={c.logo} alt={c.name} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query Clients {
        allDataYaml {
          nodes {
            clients {
              logo
              name
            }
          }
        }
      }
    `}
    render={data => <Clients data={data} {...props} />}
  />
);
